import { API_URL } from '../../../app-config/constants';
import { get, post, remove } from '../request';
const BASE_URL = `${API_URL}/automations`;
getById.operation = 'READ';
export function getById(id) {
    return get(`${BASE_URL}/${id}`);
}
list.operation = 'READ';
export function list(params) {
    return get(`${BASE_URL}`, { getData: params });
}
create.operation = 'CREATE';
create.invalidates = ['list'];
export function create(automation) {
    return post(`${BASE_URL}`, { postData: automation });
}
edit.operation = 'COMMAND';
export function edit(id, automation) {
    return post(`${BASE_URL}/${id}`, { postData: automation });
}
deleteAutomation.operation = 'DELETE';
deleteAutomation.invalidates = ['list'];
export function deleteAutomation(id) {
    return remove(`${BASE_URL}/${id}`);
}
