import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
const BASE_URL = `${API_URL}/anytime-feedback`;
getRequest.operation = 'READ';
getRequest.byId = true;
export function getRequest(id) {
    return get(`${BASE_URL}/${id}`);
}
createRequest.operation = 'CREATE';
export function createRequest(request) {
    return post(BASE_URL, { postData: request });
}
updateRequest.operation = 'COMMAND';
export function updateRequest(request) {
    return put(`${BASE_URL}/${request.id}`, { postData: request });
}
deleteRequest.operation = 'DELETE';
export function deleteRequest(requestId) {
    return remove(`${BASE_URL}/${requestId}`);
}
