import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
getPreview.operation = 'READ';
export function getPreview(type, options = {}) {
    return get(`${API_URL}/email-templates/preview/${type}`, {
        getData: options,
    });
}
getPreviewForCompany.operation = 'READ';
export function getPreviewForCompany(type, companyId) {
    return get(`${API_URL}/companies/${companyId}/emails/preview/${type}`);
}
invalidate.invalidates = ['getPreview', 'getPreviewForCompany'];
export function invalidate() {
    return Promise.resolve();
}
