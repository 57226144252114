import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
getMeetingSpaces.operation = 'READ';
export function getMeetingSpaces() {
    return get(`${API_URL}/meeting-nudges/spaces`);
}
nudge.invalidates = ['getMeetingSpaces'];
export function nudge(request) {
    return post(`${API_URL}/meeting-nudges/spaces`, {
        postData: request,
    });
}
