import { API_URL } from '../../../app-config/constants';
import { post } from '../request';
const REVIEW_IMPORT_PATH = `${API_URL}/review-import/`;
export function previewReviewImport({ cycleId, content }) {
    return post(REVIEW_IMPORT_PATH + cycleId + '/preview', {
        headers: { 'Content-Type': 'text/plain; charset=utf-8' },
        postData: content,
    });
}
export function saveReviewImport({ cycleId, content }) {
    return post(REVIEW_IMPORT_PATH + cycleId + '/import', {
        headers: { 'Content-Type': 'text/plain; charset=utf-8' },
        postData: content,
    });
}
