import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const ENTRY = `${API_URL}/meeting-spaces`;
getSharedMeetingSpacesCount.operation = 'READ';
getSharedMeetingSpacesCount.idFrom = 'ARGS';
export function getSharedMeetingSpacesCount(minUpdatedDate) {
    var _a;
    return get(`${ENTRY}/shared-count`, {
        getData: { minUpdatedDate: (_a = minUpdatedDate === null || minUpdatedDate === void 0 ? void 0 : minUpdatedDate.toISOString) === null || _a === void 0 ? void 0 : _a.call(minUpdatedDate) },
    });
}
