import { API_URL } from '../../../app-config/constants';
import { post } from '../request';
export function triggerExport(format, from, until) {
    return post(`${API_URL}/anytime-feedback-export`, {
        getData: {
            format,
            from,
            until,
        },
    });
}
export function triggerTeamExport(format, from, until) {
    return post(`${API_URL}/anytime-feedback-export/team`, {
        getData: {
            format,
            from,
            until,
        },
    });
}
