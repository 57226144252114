import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
const addId = (defaults) => (Object.assign(Object.assign({}, defaults), { id: 'anytimefeedbackdefault' }));
getAnytimeFeedbackDefault.operation = 'READ';
export function getAnytimeFeedbackDefault() {
    return get(`${API_URL}/anytime-feedback-default`).then(addId);
}
updateAnytimeFeedbackDefault.operation = 'COMMAND';
export function updateAnytimeFeedbackDefault(requestFeedbackDefault) {
    return put(`${API_URL}/anytime-feedback-default`, {
        postData: requestFeedbackDefault,
    }).then(addId);
}
