import moment from 'moment';
import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
const BASE_URL = `${API_URL}/unified-feedback`;
getYourFeedback.operation = 'READ';
export function getYourFeedback() {
    return get(`${BASE_URL}/your`);
}
getSomeonesFeedback.operation = 'READ';
export function getSomeonesFeedback(revieweeId, from, to) {
    return get(`${BASE_URL}/someones/${revieweeId}`, {
        getData: {
            // The BE expects a full ISO8601 string
            from: from === undefined ? undefined : moment(from).toISOString(false),
            to: to === undefined ? undefined : moment(to).toISOString(false),
        },
    });
}
getAnytimeFeedbackCompanyOverview.operation = 'READ';
export function getAnytimeFeedbackCompanyOverview(from, to) {
    return get(`${API_URL}/anytime-feedback-overview/company`, {
        getData: {
            from,
            to,
        },
    });
}
getAnytimeFeedbackTeamOverview.operation = 'READ';
export function getAnytimeFeedbackTeamOverview(from, to) {
    return get(`${API_URL}/anytime-feedback-overview/team`, {
        getData: {
            from,
            to,
        },
    });
}
nudgeAnytimeFeedbackReviewer.operation = 'COMMAND';
export function nudgeAnytimeFeedbackReviewer(feedbackId, reviewerId) {
    return put(`${API_URL}/anytime-feedback/${feedbackId}/nudge/${reviewerId}`);
}
invalidate.invalidates = [
    'getYourFeedback',
    'getSomeonesFeedback',
    'getAnytimeFeedbackCompanyOverview',
    'getAnytimeFeedbackTeamOverview',
];
export function invalidate() {
    return Promise.resolve();
}
