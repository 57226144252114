import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const ENTRY = `${API_URL}/surveys`;
getDates.operation = 'READ';
export function getDates(surveyId) {
    return get(`${ENTRY}/${surveyId}/dates`).then(dates => ({
        id: surveyId,
        dates,
    }));
}
