import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
const ATTACHMENTS = `${API_URL}/helpful-resources/attachments`;
getAllForAdmin.operation = 'READ';
export function getAllForAdmin() {
    return get(`${ATTACHMENTS}/admin`);
}
getAllForSidebar.operation = 'READ';
export function getAllForSidebar() {
    return get(`${ATTACHMENTS}/sidebar`);
}
const getterFunctions = ['getAllForAdmin', 'getAllForSidebar'];
createLink.operation = 'CREATE';
createLink.invalidates = getterFunctions;
export function createLink(attachment) {
    return post(`${ATTACHMENTS}/link`, { postData: attachment });
}
createFile.operation = 'CREATE';
createFile.invalidates = getterFunctions;
export function createFile(attachment) {
    return post(`${ATTACHMENTS}/file`, { postData: attachment });
}
updateLink.operation = 'UPDATE';
export function updateLink(attachment) {
    return put(`${ATTACHMENTS}/link/${attachment.id}`, { postData: attachment });
}
updateFile.operation = 'UPDATE';
export function updateFile(attachment) {
    return put(`${ATTACHMENTS}/file/${attachment.id}`, { postData: attachment });
}
deleteAttachment.operation = 'DELETE';
export function deleteAttachment(attachmentId) {
    return remove(`${ATTACHMENTS}/${attachmentId}`);
}
export function download(attachmentId) {
    return get(`${ATTACHMENTS}/${attachmentId}/download`);
}
