import { API_URL } from '../../../app-config/constants';
import { get, remove } from '../request';
const BASE_PATH = `${API_URL}/downloadable-file`;
export function getFile(id) {
    return get(`${BASE_PATH}/${id}`);
}
getAll.operation = 'READ';
export function getAll(query) {
    return get(BASE_PATH, { getData: query });
}
poll.invalidates = ['getAll'];
export function poll(query) {
    return get(BASE_PATH, { getData: query });
}
deleteDownload.operation = 'DELETE';
export function deleteDownload({ id }) {
    return remove(`${BASE_PATH}/${id}`);
}
