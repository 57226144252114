import { map, pick } from 'lodash/fp';
import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove as removeRequest } from '../request';
const ENTRY = `${API_URL}/objectives`;
const COMMENTS = '/messages';
create.operation = 'CREATE';
export function create(objective, comment) {
    return post(`${API_URL}/objective-cycles/${objective.cycleId}/objectives/${objective.id}${COMMENTS}`, {
        postData: comment,
    });
}
update.operation = 'UPDATE';
export function update(comment) {
    return put(`${ENTRY}/${comment.objectiveId}${COMMENTS}/${comment.id}`, { postData: comment });
}
remove.operation = 'DELETE';
export function remove(commentId, objectiveId) {
    return removeRequest(`${ENTRY}/${objectiveId}${COMMENTS}/${commentId}`);
}
updateVisibleTo.operation = 'UPDATE';
export function updateVisibleTo(comment) {
    const visibleTo = map(pick('id'))(comment.visibleTo);
    return put(`${ENTRY}/${comment.objectiveId}${COMMENTS}/${comment.id}/visibleTo`, { postData: visibleTo });
}
export function getAll(objectiveId) {
    return get(`${ENTRY}/${objectiveId}${COMMENTS}`);
}
