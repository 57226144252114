import { API_URL } from '../../../app-config/constants';
import { post, put } from '../request';
const BASE_URL = `${API_URL}/feedback-cycles`;
markAsDone.operation = 'NO_OPERATION';
export function markAsDone(cycleId, requestId) {
    return put(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/markAsDone`);
}
approve.operation = 'NO_OPERATION';
export function approve(cycleId, requestId) {
    return put(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/approved`);
}
updateNoteToReviewers.operation = 'NO_OPERATION';
export function updateNoteToReviewers(cycleId, requestId, note) {
    return put(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/updateNoteToReviewers`, { postData: { note } });
}
releaseFeedback.operation = 'NO_OPERATION';
export function releaseFeedback(cycleId, requestId) {
    return put(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/release-feedback`);
}
addAllManagersAsReviewers.operation = 'NO_OPERATION';
export function addAllManagersAsReviewers(cycleId) {
    return post(`${BASE_URL}/${cycleId}/feedback-requests/add-all-managers-as-reviewers`);
}
addSelfAssessments.operation = 'NO_OPERATION';
export function addSelfAssessments(cycleId) {
    return post(`${BASE_URL}/${cycleId}/feedback-requests/add-self-assessments`);
}
addTeamAssessments.operation = 'NO_OPERATION';
export function addTeamAssessments(cycleId) {
    return post(`${BASE_URL}/${cycleId}/bulk_add_team_members`);
}
