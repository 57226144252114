import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
getAnytimeFeedbackStats.operation = 'READ';
getAnytimeFeedbackStats.idFrom = 'ARGS';
export function getAnytimeFeedbackStats(from, to) {
    return get(`${API_URL}/anytime-feedback-stats`, {
        getData: {
            from,
            to,
        },
    });
}
