import { get, post, remove } from '../request';
const BASE = '/api/v2/personal-access-token';
getTokens.operation = 'READ';
getTokens.updateOnCreate = (args, x, xs) => [x, ...xs];
export function getTokens() {
    return get(BASE);
}
// this contains the revealed token - we do NOT want to cache it!
createToken.operation = 'NO_OPERATION';
export function createToken(token) {
    return post(BASE, { postData: { token } });
}
// pass the dto you received in createToken to this to store it - without the token itself
storeToken.operation = 'CREATE';
export function storeToken(token) {
    return Promise.resolve(Object.assign(Object.assign({}, token), { token: null }));
}
revokeToken.operation = 'DELETE';
export function revokeToken(tokenId) {
    return remove(`${BASE}/${tokenId}`);
}
revokeAllTokens.invalidates = ['getTokens'];
export function revokeAllTokens() {
    return remove(BASE);
}
