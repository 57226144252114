import { API_URL } from '../../../app-config/constants';
import { get, patch, post, remove } from '../request';
const ENTRY = `${API_URL}/meeting-actions`;
getMeetingActions.operation = 'READ';
export function getMeetingActions(participantIds) {
    return get(`${ENTRY}`, { getData: { participants: participantIds.join(',') } });
}
getUncheckedMeetingActions.operation = 'READ';
export function getUncheckedMeetingActions(forUserId) {
    return get(`${ENTRY}`, { getData: { forUser: forUserId } });
}
create.invalidates = ['getMeetingActions', 'getUncheckedMeetingActions'];
export function create(meetingActionCreate) {
    return post(`${ENTRY}`, { postData: meetingActionCreate });
}
update.operation = 'COMMAND';
update.invalidates = ['getUncheckedMeetingActions'];
export function update(meetingActionUpdate) {
    return patch(`${ENTRY}`, { postData: meetingActionUpdate });
}
deleteAction.operation = 'DELETE';
deleteAction.invalidates = ['getUncheckedMeetingActions'];
export function deleteAction(action) {
    return remove(`${ENTRY}/${action.id}`);
}
