import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
getById.operation = 'READ';
getById.byId = true;
export function getById(id) {
    return get(`${API_URL}/objective-cycles/` + id);
}
export function setPermissions(id, userIds) {
    return put(`${API_URL}/objective-cycles/${id}/permissions`, { postData: userIds });
}
export function addPermissions(id, userIds) {
    return post(`${API_URL}/objective-cycles/${id}/permissions`, { postData: userIds });
}
export function removePermission(id, userId) {
    return remove(`${API_URL}/objective-cycles/${id}/permissions/${userId}`);
}
export function getAllObjectivesForUser(userId) {
    return get(`${API_URL}/objective-cycles/all/objectives/view`, { getData: { userId: userId } });
}
