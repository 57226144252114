import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
const RESOURCE = `${API_URL}/user-settings`;
getUserSettings.operation = 'READ';
export function getUserSettings() {
    return get(RESOURCE);
}
updateUserSettings.operation = 'COMMAND';
export function updateUserSettings(settings) {
    return post(RESOURCE, { postData: settings });
}
setCheckedReleaseNotes.operation = 'COMMAND';
export function setCheckedReleaseNotes() {
    return post(`${RESOURCE}/checked-release-notes`);
}
