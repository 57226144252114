import { API_URL } from '../../../app-config/constants';
import { get, remove } from '../request';
const BASE_URL = `${API_URL}/slack-configurations`;
removeErrors.operation = 'DELETE';
export function removeErrors() {
    return remove(`${BASE_URL}/errors`);
}
getConfiguration.operation = 'READ';
getConfiguration.idFrom = 'ARGS';
export function getConfiguration() {
    return get(`${BASE_URL}`);
}
