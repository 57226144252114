import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
const BASE_URL = `${API_URL}/review-cycles`;
getReview.operation = 'READ';
export function getReview(cycleId, reviewId) {
    return get(`${BASE_URL}/${cycleId}/reviews/${reviewId}`);
}
listAllReviews.operation = 'READ';
export function listAllReviews(cycleId) {
    return get(`${BASE_URL}/${cycleId}/reviews`);
}
shareWithReviewee.operation = 'COMMAND';
export function shareWithReviewee(cycleId, reviewId) {
    return put(`${BASE_URL}/${cycleId}/reviews/${reviewId}/shared?target=reviewee`);
}
export function revokeShareWithReviewee(cycleId, reviewId) {
    return remove(`${BASE_URL}/${cycleId}/reviews/${reviewId}/shared?target=reviewee`);
}
export function signManager(cycleId, reviewId) {
    return put(`${BASE_URL}/${cycleId}/reviews/${reviewId}/signed?target=manager`);
}
export function indicateManagerDone(cycleId, reviewId) {
    return put(`${BASE_URL}/${cycleId}/reviews/${reviewId}/indicateManagerDone`);
}
export function shareWithManager(cycleId, reviewId) {
    return put(`${BASE_URL}/${cycleId}/reviews/${reviewId}/shared?target=manager`);
}
setBulkTimelineExtension.operation = 'COMMAND';
export function setBulkTimelineExtension(cycleId, timelineExtensions) {
    return post(`${API_URL}/review-cycle/${cycleId}/timeline-extension`, {
        postData: { extensions: timelineExtensions },
    });
}
changeManagerRatings.invalidates = ['listAllReviews'];
export function changeManagerRatings(cycleId, ratings) {
    return put(`${BASE_URL}/${cycleId}/changeManagerRatings`, {
        postData: { managerOverallRatingCodesByReview: ratings },
    });
}
changeManagerRating.operation = 'COMMAND';
export function changeManagerRating(reviewId, cycleId, managerRatingCode) {
    return put(`${BASE_URL}/${cycleId}/reviews/${reviewId}/changeManagerRating`, {
        postData: { managerRatingCode },
    });
}
changeSecondaryManagerRating.operation = 'COMMAND';
export function changeSecondaryManagerRating(reviewId, cycleId, managerRatingCode) {
    return put(`${BASE_URL}/${cycleId}/reviews/${reviewId}/changeSecondaryManagerRating`, {
        postData: { managerRatingCode },
    });
}
export function revokeShareWithManager(cycleId, reviewId) {
    return remove(`${BASE_URL}/${cycleId}/reviews/${reviewId}/shared?target=manager`);
}
export function signReviewee(cycleId, reviewId) {
    return put(`${BASE_URL}/${cycleId}/reviews/${reviewId}/signed?target=reviewee`);
}
