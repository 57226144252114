import * as Req from '../request';
create.operation = 'CREATE';
create.invalidates = ['list'];
export function create(entity) {
    return Req.post(`/api/v2/review-questionnaire`, { postData: entity });
}
get.operation = 'READ';
export function get(id) {
    return Req.get(`/api/v2/review-questionnaire/${id}`);
}
list.operation = 'READ';
export function list(includeArchived = false) {
    return Req.get(`/api/v2/review-questionnaire`, { getData: { includeArchived } });
}
update.operation = 'UPDATE';
export function update(dtoEntity) {
    return Req.put(`/api/v2/review-questionnaire/${dtoEntity.id}`, {
        postData: dtoEntity.value,
    });
}
remove.operation = 'DELETE';
export function remove(id) {
    return Req.remove(`/api/v2/review-questionnaire/${id}`);
}
