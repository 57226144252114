import { castArray } from 'lodash/fp';
import { API_URL } from '../../../app-config/constants';
import { getLaddaId } from '../../../types/EmailTemplateDto';
import { get } from '../request';
getTemplates.operation = 'READ';
getTemplates.idFrom = getLaddaId;
export function getTemplates(types, options = {}) {
    return get(`${API_URL}/email-templates-mini`, {
        getData: Object.assign({ types: types && castArray(types).join(',') }, options),
    });
}
invalidate.invalidates = ['getTemplates'];
export function invalidate() {
    return Promise.resolve();
}
