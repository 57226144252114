import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
getRelevantMeetings.operation = 'READ';
export function getRelevantMeetings({ participantIds, startDateTime, endDateTime, }) {
    return get(`${API_URL}/meetings/relevant`, {
        getData: {
            participants: participantIds.toString(),
            startDateTime,
            endDateTime,
        },
    });
}
invalidate.operation = 'NO_OPERATION';
invalidate.invalidates = ['getRelevantMeetings'];
export function invalidate() {
    return Promise.resolve();
}
