import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const BASE = `${API_URL}/admin-links`;
getSettingsLinks.operation = 'READ';
export function getSettingsLinks() {
    return get(`${BASE}/settings`);
}
getPromotionsLinks.operation = 'READ';
export function getPromotionsLinks() {
    return get(`${BASE}/links`);
}
getIntegrationsLinks.operation = 'READ';
export function getIntegrationsLinks() {
    return get(`${BASE}/integrations`);
}
