import { API_URL, PUBLIC_API_URL } from '../../../app-config/constants';
import { get as httpGet, post } from '../request';
const BASE = `${API_URL}/praise/wall`;
const BASE_OPEN = `${PUBLIC_API_URL}/praise/wall`;
export function getByToken(token) {
    return httpGet(`${BASE_OPEN}/config?token=${token}`);
}
get.operation = 'READ';
export function get() {
    return httpGet(`${BASE}/config`);
}
save.operation = 'UPDATE';
export function save(config) {
    return post(`${BASE}/config`, { postData: config });
}
