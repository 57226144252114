import { API_URL } from '../../../app-config/constants';
import { get, put, remove } from '../request';
export function getReportSubscription(reportType) {
    return get(`${API_URL}/reports/${reportType}`);
}
export function createReportSubscription(subscription) {
    return put(`${API_URL}/reports/${subscription.reportType}`, {
        postData: subscription.configuration,
    });
}
export function deleteReportSubscription(reportType) {
    return remove(`${API_URL}/reports/${reportType}`);
}
