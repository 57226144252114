import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const BASE_URL = `${API_URL}/unified-feedback`;
getFeedbackYouProvided.operation = 'READ';
export function getFeedbackYouProvided() {
    return get(`${BASE_URL}/you-provided`);
}
invalidate.invalidates = ['getFeedbackYouProvided'];
export function invalidate() {
    return Promise.resolve();
}
