import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
const RESOURCE = `${API_URL}/sparks`;
getOne.operation = 'READ';
export function getOne(storyId, typeId, visibility) {
    return get(`${RESOURCE}/${storyId}/${typeId}/${visibility}`);
}
visit.operation = 'COMMAND';
export function visit({ story, type, visibility }) {
    const params = {
        getData: { visibility },
    };
    return post(`${RESOURCE}/visit/${story}/${type}`, params);
}
dismiss.operation = 'COMMAND';
export function dismiss({ story, type }) {
    return post(`${RESOURCE}/dismiss/${story}/${type}`, {});
}
