import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const REVIEW_BASE_URL = `${API_URL}/review`;
getReviewsForReviewee.operation = 'READ';
export function getReviewsForReviewee(revieweeId) {
    return get(`${REVIEW_BASE_URL}/forReviewee/${revieweeId}`);
}
invalidateReviewsForReviewee.invalidates = ['getReviewsForReviewee'];
export function invalidateReviewsForReviewee() {
    return Promise.resolve();
}
