import { castArray } from 'lodash/fp';
import { API_URL } from '../../../app-config/constants';
import { getLaddaId } from '../../../types/EmailTemplateDto';
import { get, put, remove } from '../request';
// not using ladda's idFrom because that currently doesn't work with COMMAND or UPDATE
const addId = (template) => (Object.assign(Object.assign({}, template), { id: getLaddaId(template) }));
const addIds = (templates) => templates.map(addId);
getTemplates.operation = 'READ';
export function getTemplates(types, options = {}) {
    return get(`${API_URL}/email-templates`, {
        getData: Object.assign({ types: types && castArray(types).join(',') }, options),
    }).then(addIds);
}
saveTemplate.operation = 'COMMAND';
export function saveTemplate(emailTemplate) {
    return put(`${API_URL}/email-templates/${emailTemplate.templateType.type}`, {
        postData: emailTemplate,
    }).then(addId);
}
resetTemplate.operation = 'COMMAND';
export function resetTemplate(emailTemplateType) {
    return remove(`${API_URL}/email-templates/${emailTemplateType.type}`).then(addId);
}
invalidate.invalidates = ['getTemplates'];
export function invalidate() {
    return Promise.resolve();
}
