import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
const ENTRY = `${API_URL}/surveys`;
const updateOnCreate = (args, x, xs) => [x, ...xs];
getSingle.operation = 'READ';
getSingle.byId = true;
export function getSingle(surveyId) {
    return get(`${ENTRY}/${surveyId}`);
}
getCompanyObserverSurvey.operation = 'NO_OPERATION';
export function getCompanyObserverSurvey(surveyId) {
    return get(`${API_URL}/company-observer/surveys/${surveyId}`);
}
getAll.operation = 'READ';
getAll.updateOnCreate = updateOnCreate;
export function getAll() {
    return get(`${ENTRY}`);
}
createSurvey.operation = 'CREATE';
export function createSurvey(survey) {
    return post(`${ENTRY}`, { postData: survey });
}
getSurveySchedule.operation = 'NO_OPERATION';
export function getSurveySchedule(survey) {
    return post(`${API_URL}/survey-schedule`, { postData: survey });
}
updateSurvey.operation = 'UPDATE';
export function updateSurvey(survey) {
    return put(`${ENTRY}/${survey.id}`, { postData: survey });
}
archiveSurvey.operation = 'COMMAND';
export function archiveSurvey(surveyId) {
    return put(`${ENTRY}/${surveyId}/archive-status`, { postData: { archived: true } });
}
unarchiveSurvey.operation = 'COMMAND';
export function unarchiveSurvey(surveyId) {
    return put(`${ENTRY}/${surveyId}/archive-status`, { postData: { archived: false } });
}
updateStoppedStatus.operation = 'COMMAND';
export function updateStoppedStatus(surveyId, stopped) {
    return put(`${ENTRY}/${surveyId}/stopped-status`, { postData: { stopped } });
}
updateViewers.operation = 'COMMAND';
export function updateViewers(surveyId, viewerIds) {
    return put(`${ENTRY}/${surveyId}/viewers`, { postData: viewerIds });
}
/**
 * Do not invalidate the getSingle() call because it'd erroneously re-request the survey on the dashboard page.
 */
deleteSurvey.operation = 'NO_OPERATION';
deleteSurvey.byId = true;
deleteSurvey.invalidates = ['getAll'];
export function deleteSurvey(surveyId) {
    return remove(`${ENTRY}/${surveyId}`);
}
exportSurvey.operation = 'NO_OPERATION';
export function exportSurvey(survey, format) {
    return get(`${ENTRY}/${survey.id}/export?format=${format}`);
}
