import { API_URL } from '../../../app-config/constants';
import { get as getRequest } from '../request';
get.operation = 'READ';
export function get() {
    return getRequest(`${API_URL}/users/me`);
}
invalidate.operation = 'NO_OPERATION';
invalidate.invalidates = ['get'];
export function invalidate() {
    return Promise.resolve();
}
