import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
getPaymentDocs.operation = 'READ';
export function getPaymentDocs(type) {
    return get(`${API_URL}/your-billing/payment-docs/${type}`);
}
export function getPaymentDocDownloadLink(type, paymentDocId) {
    return get(`${API_URL}/your-billing/download/${type}/${paymentDocId}`);
}
export function getPaymentDocsForCompany(type, companyId) {
    return get(`${API_URL}/billing/${companyId}/payment-docs/${type}`);
}
export function getPaymentDocDownloadLinkForCompany(type, companyId, paymentDocId) {
    return get(`${API_URL}/billing/${companyId}/download/${type}/${paymentDocId}`);
}
