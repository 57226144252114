import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
const BADGES = 'badges';
getAll.operation = 'READ';
export function getAll() {
    return get(`${API_URL}/${BADGES}`);
}
getPrototypes.operation = 'READ';
export function getPrototypes() {
    return get(`${API_URL}/${BADGES}/prototypes`);
}
getIcons.operation = 'READ';
getIcons.idFrom = (icon) => (typeof icon === 'object' ? Object.values(icon).join('') : icon);
export function getIcons() {
    return get(`${API_URL}/${BADGES}/icons`);
}
// Create returns list of badges!
create.invalidates = ['getAll', 'getPrototypes', 'getIcons'];
export function create(badge) {
    return post(`${API_URL}/${BADGES}`, { postData: badge });
}
// Update returns list of badges!
update.invalidates = ['getAll', 'getPrototypes', 'getIcons'];
export function update(badge) {
    return put(`${API_URL}/${BADGES}/${badge.id}`, { postData: badge });
}
order.operation = 'NO_OPERATION';
order.invalidates = ['getAll', 'getPrototypes'];
export function order(newOrder) {
    return post(`${API_URL}/${BADGES}/order`, { postData: { order: newOrder } });
}
deleteBadge.operation = 'DELETE';
deleteBadge.invalidates = ['getAll', 'getPrototypes', 'getIcons'];
export function deleteBadge(id) {
    return remove(`${API_URL}/${BADGES}/${id}`);
}
