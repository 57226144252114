import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
export function getProvider() {
    return get(`${API_URL}/meeting-calendar-integration/provider`);
}
export function getConfiguration(companyId) {
    return get(`${API_URL}/meeting-calendar-integration/configuration${companyId ? '/' + companyId : ''}`);
}
export function updateConfiguration(configuration) {
    return put(`${API_URL}/meeting-calendar-integration/configuration`, {
        postData: configuration,
    });
}
export function getEvents(companyId, userId) {
    return get(`${API_URL}/meeting-calendar-integration/companies/${companyId}/users/${userId}/events`);
}
