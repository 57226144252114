import { API_URL } from '../../../app-config/constants';
import { get, remove } from '../request';
const BASE_URL = `${API_URL}/feedback-cycles`;
getCyclePreview.operation = 'READ';
export function getCyclePreview(cycleId) {
    return get(`${BASE_URL}/preview/${cycleId}`).then(wrapper => wrapper.model);
}
deleteCycle.operation = 'DELETE';
export function deleteCycle(cycleId) {
    return remove(BASE_URL + '/' + cycleId);
}
export function getCyclesWithHiddenFeedbackForUser(userId) {
    return get(`${BASE_URL}/hidden-feedback-for-user/${userId}`);
}
