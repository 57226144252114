import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
getDateTimeSettings.operation = 'READ';
export function getDateTimeSettings() {
    return get(`${API_URL}/company-settings/date-time`);
}
setDateTimeSettings.operation = 'COMMAND';
export function setDateTimeSettings(dateTimeSettings) {
    return put(`${API_URL}/company-settings/date-time`, { postData: dateTimeSettings });
}
