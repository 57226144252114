import { API_URL } from '../../../app-config/constants';
import { get, post, put } from '../request';
getAll.operation = 'READ';
export function getAll() {
    return get(`${API_URL}/your-surveys`).then(result => result.surveys);
}
getByInstanceId.operation = 'READ';
export function getByInstanceId(surveyInstanceId) {
    return get(`${API_URL}/your-surveys/${surveyInstanceId}`);
}
answerSurvey.operation = 'COMMAND';
export function answerSurvey(survey, answers) {
    return post(`${API_URL}/your-surveys/${survey.surveyInstanceId}`, {
        postData: answers,
    });
}
submitSurvey.operation = 'COMMAND';
export function submitSurvey(survey, submitted) {
    return put(`${API_URL}/your-surveys/${survey.surveyInstanceId}/submission`, {
        postData: { submitted },
    });
}
