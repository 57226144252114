import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
getAll.operation = 'READ';
getAll.idFrom = (stat) => stat.userId;
export function getAll(companyId, startDate, endDate, includeDeactivated) {
    return get(`${API_URL}/messages/${companyId}/usage-statistics`, {
        getData: {
            startDate,
            endDate,
            includeDeactivated: String(includeDeactivated),
        },
    });
}
export function triggerCsvDownload(companyId, startDate, endDate, includeDeactivated) {
    return post(`${API_URL}/messages/${companyId}/usage-statistics/export`, {
        getData: {
            startDate,
            endDate,
            includeDeactivated: String(includeDeactivated),
        },
    });
}
