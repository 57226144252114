import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
const BASE = `${API_URL}/feedback/template`;
const createApi = (type) => {
    const updateOnCreate = (args, x, xs) => [x, ...xs];
    getAll.operation = 'READ';
    getAll.updateOnCreate = updateOnCreate;
    function getAll() {
        return get(toUrl(type));
    }
    getAllActive.operation = 'READ';
    function getAllActive() {
        return get(toUrl(type, 'active'));
    }
    create.operation = 'CREATE';
    create.invalidates = ['getAllActive'];
    function create(template) {
        return post(toUrl(type), { postData: template });
    }
    edit.operation = 'UPDATE';
    edit.invalidates = ['getAllActive'];
    function edit(template) {
        return put(toUrl(template.id), { postData: template });
    }
    deleteFn.operation = 'DELETE';
    function deleteFn(id) {
        return remove(toUrl(id));
    }
    invalidateActive.invalidates = ['getAllActive'];
    function invalidateActive() {
        return Promise.resolve();
    }
    return {
        getAll,
        getAllActive,
        create,
        edit,
        deleteFn,
        invalidateActive,
    };
};
export const feedbackTemplateAnytime = createApi('ANYTIME');
export const feedbackTemplateCycle = createApi('CYCLE');
function toUrl(...path) {
    return [BASE, ...path].join('/');
}
