import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
const BASE_URL = `${API_URL}/feedback-cycles`;
addParticipant.operation = 'NO_OPERATION';
addParticipant.invalidates = ['getParticipants'];
export function addParticipant(cycleId, requestId, userId) {
    return post(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/participants`, {
        postData: [{ id: userId }],
    });
}
removeParticipant.operation = 'NO_OPERATION';
removeParticipant.invalidates = ['getParticipants'];
export function removeParticipant(cycleId, requestId, participantId) {
    return remove(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/participants/${participantId}`);
}
getParticipants.operation = 'READ';
export function getParticipants(cycleId, requestId) {
    return get(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/participants`);
}
provide.operation = 'NO_OPERATION';
provide.invalidates = ['getParticipants'];
export function provide(feedback, saveDraft) {
    return post(`${API_URL}/feedback/provide-feedback?saveDraft=${saveDraft}`, {
        postData: feedback,
    });
}
// TODO: should these be in their own file?
markCompleted.operation = 'NO_OPERATION';
markCompleted.invalidates = ['getParticipants', 'getPotentialUnsolicitedReviewees'];
export function markCompleted(cycleId, requestId, participantId) {
    return put(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/participants/${participantId}/completed`);
}
reOpen.operation = 'NO_OPERATION';
reOpen.invalidates = ['getParticipants', 'getPotentialUnsolicitedReviewees'];
export function reOpen(cycleId, requestId, participantId) {
    return remove(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/participants/${participantId}/completed`);
}
decline.operation = 'NO_OPERATION';
export function decline(cycleId, requestId, participantId, reason) {
    return put(`${BASE_URL}/${cycleId}/feedback-requests/${requestId}/participants/${participantId}/declined`, {
        postData: { reason },
    });
}
createUnsolicitedParticipant.operation = 'NO_OPERATION';
export function createUnsolicitedParticipant(feedbackRequestId) {
    return post(`${API_URL}/feedback/new-unsolicited`, { postData: { feedbackRequestId } });
}
getPotentialUnsolicitedReviewees.operation = 'READ';
export function getPotentialUnsolicitedReviewees(cycleId, reviewerId) {
    return get(`${API_URL}/feedback/unsolicited/reviewees?cycleId=${cycleId}&reviewerId=${reviewerId}`);
}
