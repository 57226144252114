import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
const BASE = `${API_URL}/notificationLogs/count`;
export function getCount() {
    return get(`${BASE}/new`);
}
resetCount.operation = 'UPDATE';
export function resetCount(countObj) {
    return post(`${BASE}/reset`, { postData: countObj });
}
