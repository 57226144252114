import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
const BASE_URL = `${API_URL}/email-verifications`;
isVerified.operation = 'READ';
isVerified.idFrom = 'ARGS';
export function isVerified(email, company) {
    return get(`${BASE_URL}/${email}/verified`, company ? { getData: { company } } : undefined);
}
export function resend(email) {
    return post(`${BASE_URL}/${email}/resend`);
}
verify.invalidates = ['isVerified'];
export function verify(postData) {
    return post(BASE_URL, { postData });
}
