import { API_URL } from '../../../app-config/constants';
import { get as httpGet, post } from '../request';
const BASE_URL = `${API_URL}/cycle-feedback-response`;
const toUrl = (responseId) => `${BASE_URL}/${responseId}`;
const executeCommand = (responseId, command, postData) => post(`${toUrl(responseId)}/${command}`, { postData });
get.operation = 'READ';
export function get(responseId) {
    return httpGet(toUrl(responseId));
}
share.operation = 'COMMAND';
export function share(responseId) {
    return executeCommand(responseId, 'share');
}
reopen.operation = 'COMMAND';
export function reopen(responseId) {
    return executeCommand(responseId, 'reopen');
}
decline.operation = 'COMMAND';
export function decline(responseId) {
    return executeCommand(responseId, 'decline');
}
answer.operation = 'COMMAND';
export function answer(responseId, answers) {
    return executeCommand(responseId, 'answer', answers);
}
