import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
export function getEmailConfiguration() {
    return get(`${API_URL}/email-configuration`);
}
export function saveEmailConfiguration(emailConfiguration) {
    return put(`${API_URL}/email-configuration`, {
        postData: emailConfiguration,
    });
}
