import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const addTempId = (event) => (Object.assign(Object.assign({}, event), { id: [event.userA, event.userB, event.when].join(':') }));
getEvents.operation = 'READ';
export function getEvents(params) {
    return get(`${API_URL}/analytics/events`, { getData: params }).then(events => events.map(addTempId));
}
getEventsForCompany.operation = 'READ';
export function getEventsForCompany(companyId, params) {
    return get(`${API_URL}/co/analytics/events`, { getData: Object.assign({ companyId }, params) }).then(events => events.map(addTempId));
}
