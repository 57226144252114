import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
// -- End user facing -- //
export var Customer;
(function (Customer) {
    const BASE_URL = `${API_URL}/your-billing`;
    getAll.operation = 'READ';
    function getAll() {
        return get(`${BASE_URL}/plans`);
    }
    Customer.getAll = getAll;
})(Customer || (Customer = {}));
// -- Company Observer facing -- //
export var CompanyObserver;
(function (CompanyObserver) {
    const CO_BASE_URL = `${API_URL}/billing`;
    getAll.operation = 'READ';
    function getAll(companyId) {
        return get(`${CO_BASE_URL}/${companyId}/plans`);
    }
    CompanyObserver.getAll = getAll;
    getAll.operation = 'READ';
    function getForLocation(location) {
        return get(`${CO_BASE_URL}/plans?location=${location}`);
    }
    CompanyObserver.getForLocation = getForLocation;
})(CompanyObserver || (CompanyObserver = {}));
