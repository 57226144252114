import { get } from 'lodash/fp';
import { API_URL } from '../../../app-config/constants';
import { get as getRequest, put } from '../request';
const RESOURCE = `${API_URL}/si-roles`;
getUsersWithRoles.operation = 'READ';
export function getUsersWithRoles() {
    return getRequest(`${RESOURCE}`);
}
// getAllRoles.operation = 'READ';
getAllRoles.idFrom = get('role');
export function getAllRoles() {
    return getRequest(`${RESOURCE}/roles`);
}
updateRoles.operation = 'COMMAND';
export function updateRoles(userId, rolesAndDates) {
    return put(`${RESOURCE}/${userId}`, {
        postData: rolesAndDates,
    });
}
