import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const ENTRY = `${API_URL}/meeting-spaces`;
const spaceId = (s) => `${s.participants[0].id}:${s.participants[1].id}`;
getSharedMeetingSpaces.operation = 'READ';
getSharedMeetingSpaces.idFrom = spaceId;
export function getSharedMeetingSpaces(minUpdatedDate) {
    var _a;
    return get(`${ENTRY}/shared`, { getData: { minUpdatedDate: (_a = minUpdatedDate === null || minUpdatedDate === void 0 ? void 0 : minUpdatedDate.toISOString) === null || _a === void 0 ? void 0 : _a.call(minUpdatedDate) } });
}
getMyMeetingSpaces.operation = 'READ';
getMyMeetingSpaces.idFrom = spaceId;
export function getMyMeetingSpaces() {
    return get(`${ENTRY}/me`);
}
