import { API_URL } from '../../../app-config/constants';
import { get, post, put } from '../request';
const CO_BASE_URL = `${API_URL}/billing`;
const wrap = (companyId) => (customer) => {
    return {
        id: companyId,
        customer,
    };
};
getCustomerForCompany.operation = 'READ';
export function getCustomerForCompany(companyId, cache) {
    return get(`${CO_BASE_URL}/${companyId}`, cache !== undefined ? { getData: { cache: String(cache) } } : {}).then(wrap(companyId));
}
invalidate.operation = 'NO_OPERATION';
invalidate.invalidates = ['getCustomerForCompany'];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function invalidate(companyId) {
    return Promise.resolve();
}
connectCompany.operation = 'COMMAND';
export function connectCompany(companyId, request) {
    return post(`${CO_BASE_URL}/${companyId}/connect`, { postData: request }).then(wrap(companyId));
}
export function updateSubscriptionPreview(companyId, update) {
    return post(`${CO_BASE_URL}/${companyId}/update-subscription-preview`, { postData: update });
}
export function estimateNextRenewal(companyId) {
    return get(`${CO_BASE_URL}/${companyId}/estimate-next-renewal`);
}
updateSubscription.operation = 'COMMAND';
export function updateSubscription(companyId, update) {
    return post(`${CO_BASE_URL}/${companyId}/update-subscription`, { postData: update }).then(wrap(companyId));
}
switchPlanModel.operation = 'COMMAND';
export function switchPlanModel(companyId) {
    return post(`${CO_BASE_URL}/${companyId}/switch-plan-model`).then(wrap(companyId));
}
export function getFreshbooksCustomerForCompany(companyId) {
    return get(`${CO_BASE_URL}/${companyId}/freshbooks-customer`);
}
export function getCollectNowUrl(companyId) {
    return post(`${CO_BASE_URL}/${companyId}/collect-now`);
}
// waiting on: https://github.com/ladda-js/ladda/pull/43
// updateFreshbooksDetails.operation = 'UPDATE';
// updateFreshbooksDetails.idFrom = (data: FreshbooksData) => data.companyId;
export function updateFreshbooksCustomer(freshbooksCustomerDto) {
    return put(`${CO_BASE_URL}/${freshbooksCustomerDto.companyId}/freshbooks-customer`, {
        postData: freshbooksCustomerDto,
    });
}
