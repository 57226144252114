import { API_URL } from '../../../app-config/constants';
import { post } from '../request';
const BASE_PATH = `${API_URL}/meeting-insights`;
// Beware: don’t just turn on caching here unless you have properly
// tested it, you can run into odd edge-cases otherwise when applying
// different queries subsequently.
export function getInsights(query) {
    return post(BASE_PATH, {
        postData: toUrlQuery(query),
    });
}
export function getTeamInsights(query) {
    return post(`${BASE_PATH}/team`, {
        postData: toUrlQuery(query),
    });
}
export function triggerCsvDownload(query) {
    return post(`${BASE_PATH}/download-csv`, {
        postData: toUrlQuery(query),
    });
}
function toUrlQuery(query) {
    const filters = (query.filters || []).reduce((prev, { key, term }) => (Object.assign(Object.assign({}, prev), { [key]: term !== null && term !== void 0 ? term : '' })), {});
    return Object.assign({}, Object.assign(Object.assign({}, query), { filters }));
}
