import { stringify } from 'query-string';
import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
const ENTRY = `${API_URL}/surveys`;
getAll.operation = 'READ';
getAll.idFrom = 'ARGS';
export function getAll(surveyId, options) {
    const urlParams = stringify(options);
    return get(`${ENTRY}/${surveyId}/responses` + (urlParams ? `?${urlParams}` : ''));
}
