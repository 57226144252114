import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
export function getCalendarSettings() {
    return get(`${API_URL}/meeting-calendar-integration/settings`);
}
updateCalendarSettings.operation = 'NO_OPERATION';
export function updateCalendarSettings(CalendarSettingsUpdate) {
    return put(`${API_URL}/meeting-calendar-integration/settings`, {
        postData: CalendarSettingsUpdate,
    });
}
