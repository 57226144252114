import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
const ENTRY = `${API_URL}/google-apps-login`;
getConfig.operation = 'READ';
export function getConfig(company) {
    return get(ENTRY, { getData: { company } });
}
updateConfig.operation = 'UPDATE';
export function updateConfig(config, waitFor) {
    return Promise.all([put(ENTRY, { postData: config }), waitFor]).then(res => res[0]);
}
