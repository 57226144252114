import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
const BASE_URL = `${API_URL}/automations`;
getAutomationsSettings.operation = 'READ';
getAutomationsSettings.idFrom = 'ARGS';
export function getAutomationsSettings() {
    return get(`${BASE_URL}/settings`);
}
setAutomationsSettings.operation = 'NO_OPERATION';
setAutomationsSettings.invalidates = ['getAutomationsSettings'];
export function setAutomationsSettings(settings) {
    return post(`${BASE_URL}/settings`, { postData: settings });
}
