import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
const BASE = `${API_URL}/release-notes`;
getLastUpdatedReleaseNotes.operation = 'READ';
export function getLastUpdatedReleaseNotes() {
    return get(BASE).then(lastDate => ({ id: 'lastUpdatedReleaseNotesDate', date: lastDate }));
}
updateLastUpdatedReleaseNotes.operation = 'COMMAND';
export function updateLastUpdatedReleaseNotes() {
    return put(BASE).then(lastDate => ({ id: 'lastUpdatedReleaseNotesDate', date: lastDate }));
}
