import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
getMessagePreview.operation = 'READ';
export function getMessagePreview({ id }) {
    return get(`${API_URL}/messages/preview/${id}`);
}
getPraisePreview.operation = 'READ';
export function getPraisePreview({ id }) {
    return get(`${API_URL}/praise/preview/${id}`);
}
getReviewPreview.operation = 'READ';
export function getReviewPreview({ cycleId, userId, reviewId }) {
    return get(`${API_URL}/reviews/preview`, { getData: { cycleId, userId, reviewId } });
}
getObjectivePreview.operation = 'READ';
export function getObjectivePreview({ id }) {
    return get(`${API_URL}/objectives/preview/${id}`);
}
