import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
getCustomerHealthData.operation = 'READ';
export function getCustomerHealthData() {
    return get(`${API_URL}/customer-health`);
}
export function getCustomerHealthDataCompany(companyId) {
    return get(`${API_URL}/customer-health/${companyId}`);
}
export function getUsage(companyId, start, end) {
    return get(`${API_URL}/customer-health/${companyId}/usage`, {
        getData: {
            start,
            end,
        },
    });
}
export function getUsageGraph(companyId, start, end) {
    return get(`${API_URL}/customer-health/${companyId}/usage-graph`, {
        getData: {
            start,
            end,
        },
    });
}
export function getEngagementGraph(companyId, start, end) {
    return get(`${API_URL}/customer-health/${companyId}/engagement-graph`, {
        getData: {
            start,
            end,
        },
    });
}
export function getHRAdmins(companyId) {
    return get(`${API_URL}/customer-health/${companyId}/hr-admins`);
}
export function getIntegrations(companyId) {
    return get(`${API_URL}/customer-health/${companyId}/integrations`);
}
export function getSupport(companyId, start, end) {
    return get(`${API_URL}/customer-health/${companyId}/support`, {
        getData: {
            start,
            end,
        },
    });
}
