import { __rest } from "tslib";
import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
query.operation = 'READ';
export function query(_a) {
    var { offset, limit, sort = 'size' } = _a, rest = __rest(_a, ["offset", "limit", "sort"]);
    return get(`${API_URL}/all-companies`, {
        getData: Object.assign({ offset: offset.toString(), limit: limit.toString(), sort }, rest),
    });
}
// Uses the same api as Company.ts, but using it here will allow ladda to return a cached version
// from the query-call. Use if you only need the smaller shape and are likely coming from the company list
getCompany.operation = 'READ';
getCompany.byId = true;
export function getCompany(id) {
    return get(`${API_URL}/companies/${id}`);
}
