// Do not implement real functions.
// These are for cache control.
notifyUpdate.operation = 'UPDATE';
export function notifyUpdate(obj) {
    return Promise.resolve(obj);
}
notifyDelete.operation = 'DELETE';
export function notifyDelete(id) {
    return Promise.resolve(id);
}
notifyCreate.operation = 'CREATE';
export function notifyCreate(obj) {
    return Promise.resolve(obj);
}
