// import USERS from '../../react/fixtures/users';
import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
const RESOURCE = `${API_URL}/users/medium`;
getAll.operation = 'READ';
export function getAll(params) {
    return get(RESOURCE, { getData: params });
}
getByIds.operation = 'READ';
getByIds.byIds = true;
export function getByIds(ids) {
    const url = `${RESOURCE}/by-ids`;
    return get(url, { getData: { ids: ids.join(',') } });
    // return Promise.resolve(ids.map(id => USERS.find(u => u.id === id)));
}
getById.operation = 'READ';
getById.byId = true;
export function getById(id) {
    const url = `${RESOURCE}/by-id`;
    return get(url, { getData: { id } });
}
getByLoginname.operation = 'READ';
export function getByLoginname(loginname) {
    const url = `${RESOURCE}/by-login`;
    return get(url, { getData: { loginname } });
}
getByLoginnames.operation = 'READ';
export function getByLoginnames(loginnames) {
    const url = `${RESOURCE}/by-logins`;
    return post(url, { postData: loginnames });
}
getByExternalSystemId.operation = 'READ';
export function getByExternalSystemId(externalSystemId) {
    return get(`${RESOURCE}/by-external-system-id/${externalSystemId}`);
}
getAllSuperUsers.operation = 'READ';
export function getAllSuperUsers() {
    const url = `${RESOURCE}/super-users`;
    return get(url);
}
getAllSuperUsersForCompany.operation = 'READ';
export function getAllSuperUsersForCompany(companyId) {
    const url = `${API_URL}/users-for-company/${companyId}/medium/super-users`;
    return get(url);
}
getAllForCompany.operation = 'READ';
export function getAllForCompany(companyId, params) {
    return get(`${API_URL}/users-for-company/${companyId}/medium`, { getData: params });
}
// TODO: enable when Ladda supports array-creates
// invite.operation = 'CREATE';
invite.invalidates = ['getAll'];
export function invite(userInvites) {
    return post(RESOURCE + '/invite', { postData: userInvites });
}
invalidate.operation = 'NO_OPERATION';
invalidate.invalidates = ['getAll'];
export function invalidate() {
    return Promise.resolve();
}
