import { API_URL } from '../../../app-config/constants';
import { get, remove as rm } from '../request';
const BASE = `${API_URL}/bounced-email/company`;
const getUrlForCompany = cId => `${BASE}/${cId}/aggregate`;
getForCompany.operation = 'READ';
export function getForCompany(companyId) {
    return get(getUrlForCompany(companyId));
}
remove.operation = 'DELETE';
export function remove(userId, companyId) {
    return rm(`${getUrlForCompany(companyId)}/${userId}`);
}
