import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
const BASE = `${API_URL}/feature-flag`;
getForCompany.operation = 'READ';
export function getForCompany(companyId) {
    return get(`${BASE}/${companyId}`);
}
getForCompanies.operation = 'NO_OPERATION';
export function getForCompanies(featureId, companyIds) {
    return get(`${BASE}`, {
        getData: { companyId: companyIds.join(','), feature: featureId },
    });
}
updateFlags.operation = 'NO_OPERATION';
export function updateFlags(companyId, flags) {
    return put(`${BASE}/${companyId}`, { postData: flags });
}
updateFlag.operation = 'UPDATE';
export function updateFlag(flag) {
    return Promise.resolve(flag);
}
updateFeatureForCompanies.operation = 'NO_OPERATION';
export function updateFeatureForCompanies(featureId, companyIds, enabled) {
    return put(`${BASE}/all-known/${featureId}`, {
        postData: {
            companyIds,
            enabled,
        },
    });
}
