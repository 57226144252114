import { API_URL } from '../../../app-config/constants';
import { get } from '../request';
getRelevantMessages.operation = 'READ';
export function getRelevantMessages({ limit, offset, projectId, authorId: author, recipientId: recipient, startDate, endDate, includeAnonymous, }) {
    return get(`${API_URL}/messages/relevant`, {
        getData: {
            fields: '$message.list',
            limit,
            offset,
            projectId,
            author,
            recipient,
            startDate,
            endDate,
            includeAnonymous,
        },
    });
}
