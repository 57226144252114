import { API_URL } from '../../../app-config/constants';
import { get, remove as rm } from '../request';
const BASE = `${API_URL}/bounced-email`;
getForCompany.operation = 'READ';
export function getForCompany(companyId) {
    return get(`${BASE}/company`, { getData: { companyId } });
}
remove.operation = 'DELETE';
export function remove(id) {
    return rm(`${BASE}/${id}`);
}
