import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
getCompany.operation = 'READ';
getCompany.byId = true;
export function getCompany(id) {
    return get(`${API_URL}/companies/${id}`);
}
activateNewBilling.operation = 'COMMAND';
export function activateNewBilling(id, location, planId, mainContactId) {
    return post(`${API_URL}/billing/${id}/activate?location=${location}&planId=${planId}&mainContact=${mainContactId}`);
}
deactivateNewBilling.operation = 'COMMAND';
export function deactivateNewBilling(id) {
    return post(`${API_URL}/billing/${id}/deactivate`);
}
editBilling.operation = 'COMMAND';
export function editBilling(id, payload) {
    return post(`${API_URL}/companies/${id}/billing`, { postData: payload });
}
mergeCompanies.operation = 'NO_OPERATION';
export function mergeCompanies(source, target, password) {
    return post(`${API_URL}/companies/merge`, { postData: { source, target, password } });
}
