import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
const ENTRY = `${API_URL}/message-settings`;
getAll.operation = 'READ';
export function getAll() {
    return get(ENTRY);
}
save.invalidates = ['getAll'];
export function save(updatedSettings) {
    return put(ENTRY, { postData: updatedSettings });
}
