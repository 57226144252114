import { API_URL } from '../../../app-config/constants';
import { get, patch, post } from '../request';
const ENTRY = `${API_URL}/experiment-observer`;
getExperiment.operation = 'READ';
export function getExperiment(codeId) {
    return get(`${ENTRY}/experiments/${codeId}`);
}
createExperiment.operation = 'CREATE';
export function createExperiment(payload) {
    return post(`${ENTRY}/experiments`, { postData: payload });
}
assignToBranches.operation = 'NO_OPERATION';
export function assignToBranches(userIds, experimentOption) {
    return post(`${ENTRY}/assign-to-branches`, { postData: { userIds, experimentOption } });
}
updateExperiment.operation = 'COMMAND';
export function updateExperiment(codeId, payload) {
    return patch(`${ENTRY}/experiments/${codeId}`, { postData: payload });
}
removeCompanyFromExperiment.operation = 'COMMAND';
export function removeCompanyFromExperiment(codeId, companyId) {
    return post(`${ENTRY}/experiments/${codeId}/remove-company?companyId=${companyId}`);
}
