import { API_URL } from '../../../app-config/constants';
import { post } from '../request';
editFeedbackCycleAdmins.operation = 'COMMAND';
export function editFeedbackCycleAdmins(userIds, cycleId) {
    return post(`${API_URL}/feedback-cycles/${cycleId}/cycle-admins`, { postData: userIds });
}
editReviewCycleAdmins.operation = 'COMMAND';
export function editReviewCycleAdmins(userIds, cycleId) {
    return post(`${API_URL}/review-cycles/${cycleId}/cycle-admins`, { postData: userIds });
}
editObjectiveCycleAdmins.operation = 'COMMAND';
export function editObjectiveCycleAdmins(userIds, cycleId) {
    return post(`${API_URL}/objective-cycles/${cycleId}/cycle-admins`, { postData: userIds });
}
