import { API_URL } from '../../../app-config/constants';
import { get, remove } from '../request';
const REVIEW_CYCLE_PATH = `${API_URL}/review-cycles/`;
getCycle.operation = 'READ';
export function getCycle(cycleId) {
    return get(REVIEW_CYCLE_PATH + cycleId);
}
list.operation = 'READ';
export function list(query = {}) {
    return get(REVIEW_CYCLE_PATH, { getData: query });
}
deleteCycle.operation = 'DELETE';
export function deleteCycle(cycleId) {
    return remove(REVIEW_CYCLE_PATH + cycleId);
}
