import { API_URL } from '../../../app-config/constants';
import { trackSprigEvent } from '../../react/services/Sprig';
import { get, patch, post, put, remove } from '../request';
const ENTRY = `${API_URL}/meetings`;
createMeeting.operation = 'CREATE';
createMeeting.invalidates = ['getMeetingsWithParticipants'];
export async function createMeeting(request) {
    const meeting = await post(ENTRY, { postData: Object.assign({}, request) });
    trackSprigEvent('Meeting Created', {
        draft: !!request.isDraft,
        fromTemplate: false,
    });
    return meeting;
}
createMeetingFromTemplate.operation = 'CREATE';
createMeetingFromTemplate.invalidates = ['getMeetingsWithParticipants'];
export async function createMeetingFromTemplate(request) {
    const meeting = await post(`${API_URL}/meeting-templates/create-meeting`, { postData: Object.assign({}, request) });
    trackSprigEvent('Meeting Created', {
        draft: !!request.isDraft,
        fromTemplate: true,
    });
    return meeting;
}
getMeetingsWithParticipants.operation = 'READ';
export function getMeetingsWithParticipants(participantIds, startDate, endDate) {
    return get(`${ENTRY}`, {
        getData: {
            participants: participantIds.join(','),
            startDateTime: startDate ? `${startDate}T00:00:00.000` : undefined,
            endDateTime: endDate ? `${endDate}T23:59:59.999` : undefined,
        },
    });
}
getMeeting.operation = 'READ';
export function getMeeting(id) {
    return get(`${ENTRY}/${id}`);
}
deleteMeeting.operation = 'DELETE';
deleteMeeting.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function deleteMeeting(payload) {
    return remove(`${ENTRY}/${payload.id}`);
}
setDate.operation = 'UPDATE';
setDate.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function setDate(payload) {
    const { date, id } = payload;
    return patch(`${ENTRY}/${id}`, {
        postData: { date },
    });
}
setCalendarDate.operation = 'UPDATE';
setCalendarDate.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function setCalendarDate(payload) {
    const { calendarDate, id } = payload;
    return patch(`${ENTRY}/${id}`, {
        postData: { calendarDate },
    });
}
setTitle.operation = 'UPDATE';
setTitle.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function setTitle(payload) {
    const { title, id } = payload;
    return patch(`${ENTRY}/${id}`, { postData: { title } });
}
setVisibleTo.operation = 'UPDATE';
setVisibleTo.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function setVisibleTo(payload) {
    const { visibleTo, id } = payload;
    return patch(`${ENTRY}/${id}`, { postData: { visibleTo } });
}
setVisibleTo.operation = 'UPDATE';
setVisibleTo.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function setTitleAndVisibleTo(payload) {
    const { title, visibleTo, id } = payload;
    return patch(`${ENTRY}/${id}`, { postData: { title, visibleTo } });
}
recordTemplateUsage.operation = 'NO_OPERATION';
export function recordTemplateUsage(payload) {
    return post(`${API_URL}/meeting-templates-usage`, {
        postData: payload,
    });
}
shareMeeting.operation = 'UPDATE';
shareMeeting.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function shareMeeting(payload) {
    const { id } = payload;
    return patch(`${ENTRY}/${id}`, { postData: { isDraft: false } });
}
// BULK UPDATE VISIBILITY
bulkUpdateVisibility.operation = 'NO_OPERATION';
bulkUpdateVisibility.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function bulkUpdateVisibility(participantIds, payload, mergeVisibility) {
    return post(`${ENTRY}/visibility`, {
        getData: {
            participants: participantIds.join(','),
            mergeVisibility: mergeVisibility.toString(),
        },
        postData: payload,
    });
}
// TALKING POINTS
createTalkingPoints.operation = 'NO_OPERATION';
createTalkingPoints.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function createTalkingPoints(payload) {
    return post(`${ENTRY}/${payload[0].meetingId}/talkingpoints`, {
        postData: payload.map(tp => {
            return Object.assign(Object.assign({}, tp), { private: tp.visibility === 'PRIVATE' });
        }),
    });
}
saveTalkingPoint.operation = 'NO_OPERATION';
saveTalkingPoint.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function saveTalkingPoint(payload) {
    return put(`${ENTRY}/${payload.meetingId}/talkingpoints/${payload.id}`, {
        postData: Object.assign(Object.assign({}, payload), { private: payload.visibility === 'PRIVATE' }),
    });
}
checkTalkingPoint.operation = 'NO_OPERATION';
checkTalkingPoint.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function checkTalkingPoint(payload) {
    return put(`${ENTRY}/${payload.meetingId}/talkingpoints/${payload.id}/check?checked=${!!payload.checked}`);
}
reorderTalkingPoint.operation = 'NO_OPERATION';
reorderTalkingPoint.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function reorderTalkingPoint(payload) {
    return put(`${ENTRY}/${payload.meetingId}/talkingpoints`, { postData: payload });
}
deleteTalkingPoint.operation = 'NO_OPERATION';
deleteTalkingPoint.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function deleteTalkingPoint(payload) {
    return remove(`${ENTRY}/${payload.meetingId}/talkingpoints/${payload.id}`);
}
// NOTES
const getNoteUrl = (note) => `${ENTRY}/${note.meetingId}/notes/${note.id}`;
createNote.operation = 'NO_OPERATION';
createNote.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function createNote(payload) {
    return post(`${ENTRY}/${payload.meetingId}/notes`, {
        postData: Object.assign(Object.assign({}, payload), { private: payload.visibility === 'PRIVATE' }),
    });
}
saveNote.operation = 'NO_OPERATION';
saveNote.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function saveNote(note) {
    const url = getNoteUrl(note);
    return put(url, { postData: Object.assign(Object.assign({}, note), { private: note.visibility === 'PRIVATE' }) });
}
deleteNote.operation = 'NO_OPERATION';
deleteNote.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function deleteNote(note) {
    const url = getNoteUrl(note);
    return remove(url);
}
// ATTACHMENTS
addAttachment.operation = 'READ';
addAttachment.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function addAttachment(meetingId, createCommand) {
    return post(`${ENTRY}/${meetingId}/attachments`, { postData: createCommand });
}
deleteAttachment.operation = 'READ';
deleteAttachment.invalidates = ['getMeetingsWithParticipants', 'getMeeting'];
export function deleteAttachment(meetingId, attachmentId) {
    return remove(`${ENTRY}/${meetingId}/attachments/${attachmentId}`);
}
triggerCsvExport.operation = 'NO_OPERATION';
export function triggerCsvExport(data) {
    return post(`${ENTRY}/export-content-csv?from=${data.from || ''}&until=${data.until || ''}`);
}
