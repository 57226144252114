import { API_URL } from '../../../app-config/constants';
import { get, post, put, remove } from '../request';
const ENTRY = `${API_URL}/meeting-templates`;
getMeetingTemplates.operation = 'READ';
export function getMeetingTemplates(requiredPermission, type) {
    return get(ENTRY, {
        getData: {
            'required-permission': requiredPermission,
            type,
        },
    });
}
getMeetingTemplate.operation = 'READ';
export function getMeetingTemplate(id) {
    return get(`${ENTRY}/${id}`);
}
saveTemplate.operation = 'UPDATE';
saveTemplate.invalidates = ['getMeetingTemplates'];
export function saveTemplate(payload) {
    return put(`${ENTRY}/${payload.id}`, { postData: payload });
}
createTemplate.operation = 'CREATE';
createTemplate.invalidates = ['getMeetingTemplates'];
export function createTemplate(payload) {
    return post(`${ENTRY}`, { postData: payload });
}
removeTemplate.operation = 'DELETE';
removeTemplate.invalidates = ['getMeetingTemplates'];
export function removeTemplate(id) {
    return remove(`${ENTRY}/${id}`);
}
