import { API_URL } from '../../../app-config/constants';
import { get, put } from '../request';
const BASE = `${API_URL}/replacements`;
export function getDefaults() {
    return get(`${BASE}/defaults`);
}
export function getCustomizations() {
    return get(`${BASE}/customizations`);
}
export function updateCustomizations(customizations) {
    return put(`${BASE}/customizations`, { postData: customizations });
}
// Company Observer
export function getCurrentCustomizations() {
    return get(BASE);
}
export function getCustomizationsForCompany(companyId) {
    return get(`${BASE}/${companyId}/customizations`);
}
export function updateCustomizationsForCompany(companyId, customizations) {
    return put(`${BASE}/${companyId}/customizations`, { postData: customizations });
}
