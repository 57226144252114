import { get, put } from '../request';
export function getCompanyAnalytics(companyId) {
    return get('/api/v2/company-analytics/' + companyId);
}
export function recalculateCompanyAnalytics(companyId) {
    return put('/api/v2/company-analytics/' + companyId);
}
export function getCompanyCreated(companyId) {
    return get('/api/v2/company-analytics/company-created/' + companyId);
}
